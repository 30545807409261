<!--积分调整 -->
<template>
  <div>
    <ax-table
      ref="tableBox"
      :columns="columns"
      :show-search="true"
      :searchForm="searchForm"
      :toolActions="toolActions"
      @add="add"
      :showToolBar="true"
      @action-column-click="actionColumnClick"
      :dynamicSlotNames="['gridId']"
      :dataSourceApi="api.list"
      :dataSourceParams="dataSourceParams"
      :scroll="{ y: '55vh', x: '80vw' }"
    >
      <template slot="type" slot-scope="{ record }">{{
        record.type === "0" ? "加分" : "减分"
      }}</template>
      <template slot="gridName" slot-scope="{ record }">{{
        record.gridName ? record.gridName : "--"
      }}</template>
      <template slot="phone" slot-scope="{ record }">{{
        record.phone ? record.phone : "--"
      }}</template>
      <template slot="residentName" slot-scope="{ record }">{{
        record.residentName ? record.residentName : "--"
      }}</template>
    </ax-table>

    <el-dialog
      title="添加积分调整"
      :visible.sync="dialogAdd"
      width="30%"
      :z-index="12"
      :close-on-click-modal="false"
    >
      <ax-form ref="formBox" :formBuilder="formBuilder">
        <div slot="personName" style="display: flex">
          <a-input disabled :value="personName" placeholder="请选择"></a-input>
          <ax-button @click="choosePeople" style="margin-left: 2%"
            >选择</ax-button
          >
        </div>
      </ax-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeModal()">取 消</el-button>
        <el-button
          style="background-color: #556bff; color: #fff"
          :loading="saveType"
          @click="Submit"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 选择人员 -->
    <choosePersonnel
      ref="peopleAdd"
      @receive="receive"
      :personInfo="personInfo"
    ></choosePersonnel>
    <!-- 详情弹窗 -->
    <detaila ref="detaila" @refresh="refresh"></detaila>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
const searchForm = [
  {
    label: "姓名",
    type: "input",
    model: "residentName",
    options: { placeholder: "请输入" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "联系电话",
    type: "input",
    model: "phone",
    options: { placeholder: "请输入" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "所属网格",
    type: "cascader",
    model: "gridId",
    options: { placeholder: "请选择" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "调整类型",
    type: "select",
    model: "type",
    options: {
      options: [
        { label: "加分", value: "0" },
        { label: "减分", value: "1" },
      ],
      placeholder: "请选择",
    },
    formItem: {},
    col: { span: 6 },
  },
];
const formList = [
  {
    label: "选择人员",
    type: "select",
    model: "personName",
    options: {
      placeholder: "请选择",
    },
    col: { span: 24 },
  },
  {
    label: "调整类型",
    type: "radio",
    model: "type",
    options: {
      options: [
        { label: "加分", value: "0" },
        { label: "减分", value: "1" },
      ],
    },
    col: { span: 24 },
    rules: [{ required: true, message: "请选择" }],
  },
  {
    label: "积分数值",
    type: "inputNumber",
    model: "pointsInt",
    options: {
      placeholder: "请输入",
    },
    col: { span: 24 },
    rules: [{ required: true, message: "请输入" }],
  },
  {
    label: "调整说明",
    type: "textarea",
    model: "message",
    options: {
      minRows: 4,
      maxRows: 4,
      allowClear: true,
      placeholder: "请输入",
      maxLength: 200,
    },
    col: { span: 24 },
    rules: [{ required: true, message: "请输入" }],
  },
];
import api from "./api";
import choosePersonnel from "@/pages/views/Acomponents/choosePersonnel";
import detaila from "./components/details.vue";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { choosePersonnel, detaila },
  data() {
    //这里存放数据
    return {
      api,
      // 表格模块数据
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "姓名",
            dataIndex: "residentName",
            ellipsis: false,
            width: 120,
          },
          {
            title: "联系电话",
            dataIndex: "phone",
            ellipsis: false,
            width: 100,
          },
          {
            title: "身份证号",
            dataIndex: "identityCard",
            ellipsis: true,
            width: 180,
          },
          {
            title: "所属网格",
            dataIndex: "gridName",
            ellipsis: false,
            width: 150,
          },
          {
            title: "调整类型",
            dataIndex: "type",
            ellipsis: false,
            width: 150,
          },
          {
            title: "积分数值",
            dataIndex: "pointsString",
            ellipsis: false,
            width: 150,
          },
          {
            title: "调整说明",
            dataIndex: "message",
            ellipsis: false,
            width: 150,
          },
          {
            title: "操作人",
            dataIndex: "createBy",
            ellipsis: false,
            width: 150,
          },
          {
            title: "操作时间",
            dataIndex: "createTime",
            ellipsis: false,
            width: 150,
          },
        ],
        false,
        {
          width: 180,
          // 操作部分
          actions: [
            {
              props: (record) => {
                return {
                  text: "详情",
                  name: "detail",
                  type: "#f1ce93",
                  link: true,
                  popDisabled: true,
                  // disabled: record.isAbandon === "1",
                };
              },
            },
          ],
        }
      ),
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 100,
      }),
      toolActions: [{ name: "add", text: "新增" }],
      dataSourceParams: {},
      // 弹窗表单
      formBuilder: this.$common.initGridFormData(
        formList,
        { layout: "horizontal", labelWidth: 120 },
        { col: { span: 8 } }
      ),
      dialogAdd: false,
      peopleAdd: false,
      saveType: false,
      personName: "",
      personInfo: [],
      personNameID: [],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    async getgridTree() {
      try {
        const res = await api.gridTree(); // 调用接口获取数据
        if (res.success) {
          // 如果接口请求成功
          const options = this.processData(res.data); // 处理数据
          this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp(
            "gridId",
            {
              options: {
                options,
                allowClear: true,
                placeholder: "上级网格",
                changeOnSelect: true,
              },
            }
          );
        } else {
          console.error("获取所属网格数据失败:", res.message); // 输出错误信息
        }
      } catch (error) {
        console.error("获取所属网格数据异常:", error); // 输出异常信息
      }
    },
    // 处理数据为级联选择器格式
    processData(data) {
      return data.map((item) => ({
        value: item.id,
        label: item.name,
        children: item.children ? this.processData(item.children) : [], // 递归处理子节点
      }));
    },
    refresh(){
      this.$refs.tableBox.getDataSource();
    },
    // 表格操作
    async actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        // 编辑
        case "detail":
          this.$refs.detaila.openModal(record);

          break;
      }
    },
    refsh() {
      this.$refs.tableBox.getDataSource();
    },
    // 新增弹窗
    add() {
      this.dialogAdd = true;
    },
    // 选择人员弹窗
    choosePeople() {
      this.$refs.peopleAdd.openModal();
    },
    receive(e) {
      this.personName = e.map((item) => item.residentName).toString();
      this.personInfo = e;
      this.personNameID = e.map((item) => item.id);
    },
    // 弹窗保存
    Submit() {
      this.$refs.formBox.form.validateFields(async (err, value) => {
        if (err) return;
        this.saveType = true;
        try {
          let obj = {
            residentIds: this.personNameID,
            ...value,
          };
          const res = await api.addPointsAdjust(obj);
          if (res.status == 200) {
            this.$message.success("操作成功");
            this.closeModal();
            this.saveType = false;
            this.$refs.tableBox.getDataSource();
          } else {
            this.$message.error("操作失败");
            this.saveType = false;
          }
          this.saveType = false;
        } catch (error) {
          console.error(error);
          this.saveType = false;
        }
      });
    },
    closeModal() {
      this.dialogAdd = false;
      this.$refs.formBox.resetFields();
      this.personName = "";
      this.personNameID = [];
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.$nextTick(() => {
      this.getgridTree();
    });
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}
</style>