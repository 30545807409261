<!-- 选择居民弹窗-->
<template>
  <div>
    <el-dialog
      title="选择人员"
      :visible.sync="visible"
      width="60%"
      @close="closeModal"
      :close-on-click-modal="false"
    >
      <!-- 中间表格 -->
      <div class="centers">
        <div class="titleBox">
          <div class="titleLine" style=""></div>
          <div>{{ titles }}</div>
        </div>
        <ax-table
          ref="tableBox"
          :columns="columns"
          :show-search="true"
          :searchForm="searchForm"
          :searchActions="searchActions"
          :isCard="false"
          :showToolBar="false"
          :dataSourceApi="api.listPeople"
          :dataSourceParams="dataSourceParams"
          :rowSelection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
            getCheckboxProps: getCheckboxProps,
          }"
        >
          <template slot="phone" slot-scope="{ record }">{{
            record.phone ? record.phone : "--"
          }}</template>
        </ax-table>
      </div>
      <!-- 右侧选择人员数据回显 -->
      <div class="right">
        <!-- 左边竖线样式写法 -->
        <div style="display: flex; justify-content: space-between">
          <div class="title">已选择：</div>
          <div
            style="color: #2a5cff; cursor: pointer"
            @click="clearSelectedRows"
          >
            清空
          </div>
        </div>
        <div
          v-for="(item, index) in selectedRows"
          :key="index"
          :class="['select-people', { active: activeIndex === index }]"
          @click="setActive(index)"
        >
          <div>
            {{ item.residentName }}
          </div>
          <a-icon type="close" @click="del(item)" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button
          style="background-color: #275dff; color: #fff"
          @click="setPeopleId"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
  <script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import api from "./api";
const searchForm = [
  {
    label: "关键字",
    type: "input",
    model: "searchText",
    options: { placeholder: "姓名/身份证号/联系电话" },
    formItem: {},
    col: { span: 8 },
  },
  {
    label: "所属网格",
    type: "cascader",
    model: "gridId",
    options: { placeholder: "请选择" },
    formItem: {},
    col: { span: 8 },
  },
];
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    personInfo: {
      type: Array,
    },
    // 提示
    titles: {
      type: String,
      default: "居民列表",
    },
  },

  data() {
    //这里存放数据
    return {
      api,
      visible: false,
      // 中间表格
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "姓名",
            dataIndex: "residentName",
            ellipsis: true,
            maxWidth: 50,
          },
          {
            title: "联系电话",
            dataIndex: "phone",
            ellipsis: false,
            maxWidth: 50,
          },
          {
            title: "身份证号",
            dataIndex: "identityCard",
            ellipsis: true,
            maxWidth: 180,
          },
        ],
        false
      ),
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 80,
      }),
      treeData: [],
      searchActions: [
        { name: "search", text: "查询" },
        { name: "reset", text: "重置" },
      ],
      selectedRows: [],
      selectedRowKeys: [],
      dataSourceApis: api.list,
      dataSourceParams: {},
      // 右侧列表
      activeIndex: null,
      allRows: [],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 打开弹窗
    openModal(record = {}) {
      this.visible = true;
      this.$nextTick(() => {
        this.getTableListData();
        this.getgridTree();
      });
      if (this.personInfo) {
        this.selectedRows = this.personInfo;
        this.selectedRowKeys = this.personInfo.map((user) => user.id);
      }
    },
    async getgridTree() {
      try {
        const res = await api.gridTree(); // 调用接口获取数据
        if (res.success) {
          // 如果接口请求成功
          const options = this.processData(res.data); // 处理数据
          setTimeout(() => {
            this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp(
              "gridId",
              {
                options: {
                  options,
                  allowClear: true,
                  placeholder: "上级网格",
                  changeOnSelect: true,
                },
              }
            );
          }, 1000);
        } else {
          console.error("获取所属网格数据失败:", res.message); // 输出错误信息
        }
      } catch (error) {
        console.error("获取所属网格数据异常:", error); // 输出异常信息
      }
    },
    // 处理数据为级联选择器格式
    processData(data) {
      return data.map((item) => ({
        value: item.id,
        label: item.name,
        children: item.children ? this.processData(item.children) : [], // 递归处理子节点
      }));
    },
    // 中间表格操作区
    async getTableListData() {
      const obj = {
        pageNo: 1,
        pageSize: 10,
      };
      this.$refs.tableBox.getDataSource(obj);
    },
    getCheckboxProps(record) {
      if (this.personInfo) {
        const isChecked = this.personInfo.some((user) => user.id === record.id);
        return {
          checked: isChecked,
        };
      }
      return {};
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      // 更新 selectedRowKeys
      this.selectedRowKeys = selectedRowKeys;
      // 如果 this.allRows 不存在需要初始化
      if (!this.allRows) {
        this.allRows = [];
      }
      // 合并新选中的行到 allRows 中
      this.allRows.push(...selectedRows);
      // 去重处理 - 使用一个 Map 来确保唯一性
      const rowMap = new Map();
      this.allRows.forEach((row) => {
        rowMap.set(row.id, row); // 假设 row.id 是唯一标识符
      });
      // 将 Map 的值转换为数组并赋值给 this.allRows
      this.allRows = Array.from(rowMap.values());
      // 只保留在 selectedRowKeys 中的行
      this.selectedRows = this.allRows.filter((row) =>
        selectedRowKeys.includes(row.id)
      );
    },
    setPeopleId() {
      if (this.selectedRowKeys.length == "0") {
        this.visible = true;
        this.$message.warning("请选择人员");
      } else {
        this.visible = false;
        this.$emit("receive", this.selectedRows);
      }
    },

    // 右侧已选择操作区
    setActive(index) {
      console.log(index);
      if (index === this.activeIndex) {
        this.activeIndex = null;
      } else {
        this.activeIndex = index;
      }
    },
    // 删除对应人员
    del(item) {
      this.selectedRows = this.selectedRows.filter((i) => i.id !== item.id);
      this.selectedRowKeys = this.selectedRowKeys.filter(
        (key) => key !== item.id
      );
      this.getTableListData();
    },
    // 清空已选择人员
    clearSelectedRows() {
      this.selectedRows = [];
      this.selectedRowKeys = [];
    },
    // 关闭弹窗
    closeModal() {
      this.selectedRows = [];
      this.visible = false;
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
  
  <style lang="less" scoped>
.titleBox {
  display: flex;
  align-items: center;
  font-size: 0.07rem;
  font-weight: 700;
  color: #2d3138;
  .titleLine {
    height: 0.08rem;
    width: 0.013rem;
    background-color: #2a5cff;
    margin-right: 5px;
  }
}
.dialog-footer {
  display: flex;
  justify-content: end;
}
/deep/.el-dialog__body {
  width: 100%;
  display: flex;
  justify-content: space-between;
  // height: 50vh;
  padding-top: 10px;
}

.centers {
  width: 70%;
}
.right {
  padding: 1% 1%;
  width: 25%;
  border: 1px solid #c9ced1;
  .select-people {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    margin: 5% 1%;
    padding: 5% 2%;
    height: 20px;
  }
  .select-people:hover {
    background-color: #f1f2f6;
  }
}
.active {
  background-color: #f0f2f6;
}
</style>