import { post, deletes, get, downFile } from 'common/request'
export default {
  /**
   * 获取
   */
  //列表数据
  list: params => get('/scPointsAdjust/adjustRecordList', params),
  // 网格树
  gridTree: args => get('/scGridManagement/getTreeList', args),
  /**
   * 插入/修改
   */
  //  新增
  addPointsAdjust: args => post('/scPointsAdjust/add', args),

  /**
     * 字典
     */
  // form里面的下拉获取数据字典
  // dictData: args => get('/dict-data/getByDicKind', args),
}
